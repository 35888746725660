import React from 'react'


class Timer extends React.Component {

  // Creating state
  constructor(props) {
    super(props);

    this.start_time = Date.now();
    this.state = {
      time: 0,
      years: '0000',
      days: '000',
      hours: '00',
      minutes: '00',
      seconds: '00',
      deciseconds: '00',
    }
  }

  componentDidMount = () => {
    setInterval(() => {

      // Compute current time.
      let new_time = Date.now() - this.start_time;

      this.setState(prevState => ({
        time: new_time,
        deciseconds: Math.floor((new_time / 10) % 100).toString().padStart(2, 0),
        seconds: Math.floor((new_time / 1000) % 60).toString().padStart(2, 0),
        minutes: Math.floor((new_time / 1000 / 60) % 60).toString().padStart(2, 0),
        hours: Math.floor((new_time / 1000 / (60 * 60)) % 24).toString().padStart(2, 0),
        days: Math.floor(new_time / 1000 / (60 * 60 * 24) % 365).toString().padStart(3, 0),
        years: Math.floor(new_time / 1000 / (60 * 60 * 24) / 365).toString().padStart(4, 0),
      }));
    }, 10);
  }

  render() {
    return (
      <div className='timer'>
        <h1>{this.state.years}:{this.state.days}:{this.state.hours}:{this.state.minutes}:{this.state.seconds}:{this.state.deciseconds}</h1>
      </div>
    );
  }
};

export default Timer;
