import './App.css';
import Timer from './components/Timer.js';
import InsertUser from './components/insertUser.js';

function App() {
  return (
    <div className="App">
      <Timer />
      <InsertUser />
    </div>
  );
}

export default App;
