import React, { Fragment, useState } from "react";

const InsertUser = () => {

    const [name, setName] = useState("")

    const onSubmitForm= async e => {
        e.preventDefault();
        try {
            let start_time = Date.now();
            const body = { name, start_time };
            const response = await fetch("https://keepthispageopen.com/api/save", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body)
            });

            console.log(response);
        } catch (err) {
            console.error(err.message);
        }
    }

    return (
        <Fragment>
            <h1>penatrate user</h1>
            <form className="newUserForm" onSubmit={onSubmitForm}>
                <input type="textbox" value={name} onChange={e => 
                setName(e.target.value)} />
                <button type="submit">submit name</button>
            </form>
        </Fragment>
        
    )
}

export default InsertUser;